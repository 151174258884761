<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pm-0 d-flex justify-content-between flex-wrap">
        <div class="d-flex">
          <FormInput type="search" id="search" placeholder="اسم الفئة" input-class="max-w-200px" @changed="searchChanged" />
          <button class="btn btn-info btn-sm rounded-0" @click="filter">
            <tooltip title="بحث">
              <i class="fa fa-search"></i>
            </tooltip>
          </button>
        </div>

        <modal-btn :target="`#${storeId}`" btn-class="btn-info btn-sm">
          <span>إضافة فئة</span>&nbsp;
          <i class="fa fa-plus"></i>
        </modal-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="table-responsive">
          <table class="table table-bordered" :class="{'shadow-sm': !emptyList}">
            <thead>
            <tr class="bg-bb">
              <th>رقم الفئة</th>
              <th>الاسم</th>
              <th>تاريخ الإنشاء</th>
              <th></th>
            </tr>
            </thead>
            <tbody class="bg-light">
            <tr v-if="emptyList">
              <td colspan="4" class="font-weight-bolder">لا يوجد</td>
            </tr>
            <ManufacturersCategoriesItem
              v-else
              v-for="item in list"
              :key="item.id"
              :category="item"
              @updated="updated"
              @deleted="deleted"
            />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>

    <teleport to="#app">
      <confirm-model
        :target-id="storeId"
        title="إضافة فئة"
        confirm-text="إضافة"
        @confirmed="clickById(storeSubmitId)">
        <form @submit.prevent="store">
          <div class="form-group">
            <label>إسم الفئة</label>
            <FormInput id="content" placeholder="إسم الفئة" />
          </div>
          <button hidden :id="storeSubmitId"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import FormInput from "@/components/Forms/FormInput"
import Tooltip from "@/components/UI/Tooltip"
import ModalBtn from "@/components/UI/ModalBtn"
import ThePaginator from "@/components/UI/ThePaginator"
import ConfirmModel from "@/components/UI/ConfirmModel"
import pagination from "@/mixins/pagination"
import ManufacturersCategoriesItem from "@/components/Admin/ManufacturersCategoriesItem"

export default {
  name: "ManufacturersCategories",
  components: {ConfirmModel, ThePaginator, ModalBtn, Tooltip, FormInput, ManufacturersCategoriesItem},
  mixins: [pagination],
  data() {
    return {
      search: ''
    }
  },
  computed: {
    car_manufacturer_id() {
      return this.$route.params.car
    },
    defaultUrl() {
      return 'car-categories'
    },
    storeId() {
      return 'car-categories-store'
    },
    storeSubmitId() {
      return 'car-categories-submit'
    },
    formData() {
      return {
        car_manufacturer_id: this.car_manufacturer_id
      };
    },
  },
  methods: {
    async store(e) {
      const formData = new FormData(e.target)

      if (!formData.get('content'))
        return http.errorMessage(messages.checkFields)

      formData.set('car_manufacturer_id', this.car_manufacturer_id)
      const response = await http.send(this.defaultUrl, formData)

      if (http.responseAccepted(response)) {
        this.updated(response.data.category)
        http.successMessage(messages.saved)
        this.hideModal(`#${this.storeId}`)
        $_('#content').val('')
      }
    },
    searchChanged(event) {
      this.search = !!event ? event.target.value : ''
    },
    async filter() {
      const response = await http.send(this.defaultUrl, {_method: 'GET', search: this.search})

      if (http.responseAccepted(response)) {
        this.setPagination(response.data)
      }
    }
  },
  async mounted() {
    await this.setList()
  }
}
</script>